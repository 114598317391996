@tailwind base;
@tailwind components;
@tailwind utilities;

body,
button,
div,
form,
h1,
h2,
h3,
html,
input,
li,
p,
textarea,
ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3 {
  padding: 2px 0;
  margin: 0 auto;
}

*,
:after,
:before {
  box-sizing: inherit;
}

body {
  font-family: var(--font-family);
  line-height: 1.6;
  color: var(--primary-color);
  background-color: #f9f9f9;
}

.page-container {
  box-shadow: var(--nav-box-shadow);
  padding: var(--global-padding);
}

:root {
  --primary-color: #0056b3;
  --secondary-color: #495057;
  --background-color: #ffffff;
  --border-color: #ddd;
  --nav-bg: #1d1d1d;
  --nav-shadow: rgba(0, 0, 0, 0.1);
  --toolbar-bg: #0056b3;
  --toolbar-hover-bg: #01447e;
  --nav-link-active-border: 2px solid white;
  --font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  --monospace-font: "Courier New", Courier, monospace;
  --standard-font-size: 14px;
  --h2-font-size: 1.2em;
  --h3-font-size: 1em;
  --editor-line-height: 1.5;
  --global-padding: 5px;
  --nav-container-padding: 5px;
  --global-margin: 0;
  --nav-padding: 20px;
  --nav-margin: 2px auto 5px auto;
  --nav-box-shadow: 0px 4px 10px var(--nav-shadow);
  --nav-border-radius: 5px;
  --nav-gap: 5px;
  --primary-gap: 0.2rem;
  --toolbar-height: 35px;
  --menu-width: 170px;
  --menu-height: 500px;
  --arrow-svg-height: 70px;
  --arrow-svg-svg-width: 60px;
  --arrow-svg-svg-height: 20px;
  --transition-duration: 0.5s;
}

.tool_title_icon {
  color: var(--primary-color) !important;
  filter: none;
}

h1,
h2 {
  font-size: var(--h2-font-size);
}

h3 {
  font-size: var(--h3-font-size);
  font-weight: 400;
}

.footer-bar,
.nav-bar,
nav {
  padding: var(--global-padding);
}

nav {
  background-color: var(--nav-bg);
  color: #fff;
  box-shadow: var(--nav-box-shadow);
  border-radius: var(--nav-border-radius);
}

.footer-bar,
.nav-bar,
h1,
h2 {
  display: flex;
  align-items: center;
}

.footer-bar,
.nav-bar {
  background-color: var(--primary-color);
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 0;
}

.footer-container,
.nav-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: var(--nav-container-padding);
}

.nav-title-link {
  display: flex;
  -webkit-flex-direction: row;
}

.nav-list li a,
.nav-title-link {
  text-decoration: none;
  color: #fff;
  width: 100%;
  margin-bottom: 10px;
}

.nav-list,
.nav-title {
  text-align: left !important;
  margin: 0 !important;
  padding: 0 !important;
}

.nav-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--nav-gap);
  width: 100%;
}

.nav-link {
  padding: 5px 10px;
  background-color: var(--primary-color);
  border-radius: var(--nav-border-radius);
  transition: background var(--transition-duration);
}

.nav-link.active {
  background-color: var(--background-color);
  color: var(--primary-color);
}

.nav-link,
.nav-link:hover {
  color: var(--background-color);
}

.input-toolbar button:hover,
.nav-link:hover,
.output-toolbar button:hover,
.toolbar-menu button:hover {
  background: var(--toolbar-hover-bg);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.editor,
.json-editor {
  border: 1px solid var(--border-color);
  border-radius: var(--nav-border-radius);
  background-color: var(--background-color);
  font-family: var(--monospace-font);
  font-size: var(--standard-font-size);
  line-height: var(--editor-line-height);
}

.jsoneditor-menu {
  color: #fff;
  background-color: var(--primary-color) !important;
  border-bottom: 1px solid var(--primary-color) !important;
}

.editor {
  width: 100%;
  min-height: 400px;
  overflow: auto;
  transition: box-shadow var(--transition-duration) ease;
}

.editor:focus-within {
  border-color: var(--primary-color);
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

.json-editor {
  color: #333;
}

.json-editor pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.jsoneditor {
  border: 0 !important;
}

.jsoneditor .jsoneditor-selected {
  background-color: #f7f7f7 !important;
}

.ace-jsoneditor .ace_marker-layer .ace_active-line {
  background: rgba(0, 0, 0, 0.07) !important;
}

.ace-jsoneditor.ace_editor,
textarea.jsoneditor-text.ace_editor {
  height: 500px !important;
}

.editor-box {
  display: flex;
  flex-direction: column;
}

.json-editor {
  height: 100%;
  overflow: hidden;
}

.ace_editor {
  width: 100% !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
  font-family: var(--monospace-font) !important;
  font-size: var(--standard-font-size) !important;
  line-height: var(--editor-line-height) !important;
  border: 1px solid var(--border-color);
  border-radius: var(--nav-border-radius);
  background-color: var(--background-color);
  height: 500px !important;
}

.ace_content {
  overflow: hidden !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ace_content::-webkit-scrollbar {
  display: none !important;
}

.ace_gutter {
  background-color: #f0f0f0;
  color: #666;
  font-family: var(--monospace-font);
  font-size: var(--standard-font-size);
  line-height: var(--editor-line-height);
}

.ace_print-margin {
  display: none;
}

@media (max-width: 768px) {
  .editor,
  .json-editor {
    min-height: 300px;
    font-size: 12px;
    line-height: 1.4;
  }

  .ace_gutter {
    font-size: 12px;
  }
}

.editor-box {
  flex: 1;
  min-width: 0;
  text-align: left;
}

.jsoneditor-menu {
  display: inline-flex;
}

.menu-container,
.menu-elems {
  display: flex;
  flex-direction: column;
}

.menu-container {
  width: var(--menu-width);
  gap: 0 !important;
  height: var(--menu-height);
  background-color: transparent;
}

.menu-elems {
  width: 100%;
  gap: var(--primary-gap);
  padding: 0;
}

.arrow-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--arrow-svg-height);
  border: 0;
}

.arrow-svg svg {
  width: var(--arrow-svg-svg-width);
  height: var(--arrow-svg-svg-height);
}

.toolbar {
  height: 100%;
}

.toolbar-sub {
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 var(--primary-gap);
}

.input-toolbar,
.output-toolbar,
.toolbar,
.toolbar-menu {
  height: var(--toolbar-height) !important;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  background-color: var(--toolbar-bg);
  border-bottom: 1px solid var(--toolbar-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0 auto;
}

.input-toolbar button,
.output-toolbar button,
.toolbar-menu button {
  background: 0 0;
  color: #fff;
  border: 0;
  cursor: pointer;
}

.editor-box h3 {
  text-align: center;
  margin: 0;
  background-color: silver;
  color: #222;
  padding: var(--primary-gap);
  border-radius: var(--nav-border-radius) var(--nav-border-radius) 0 0;
}

.btn.label-style,
button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar-sub img,
button,
button img {
  background-color: transparent;
  color: #fff;
}

button {
  gap: 8px;
  border: 1px solid #ccc;
  border-radius: var(--nav-border-radius);
  cursor: pointer;
  transition: background var(--transition-duration);
  text-align: center;
  border-width: 0;
}

.menu-elems button {
  padding: 5px;
}

.toolbar-sub img,
button img {
  width: 20px;
  height: 20px;
  margin: 2px;
  padding: 0;
  border-radius: 2px;
  border: 1px solid transparent;
  opacity: 0.8;
  font-family: arial, sans-serif;
  float: left;
  filter: brightness(0) invert(1);
}

.btn.label-style {
  position: relative;
}

.btn.label-style img {
  position: absolute;
  left: 8px;
}

.label-style,
.page-container.toolinfos p,
.toolbar-sub img,
button,
button img,
select {
  font-size: var(--standard-font-size);
  color: var(--primary-color);
}

select {
  width: 100%;
}

.label-style {
  padding: var(--primary-gap);
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
  border-radius: var(--nav-border-radius);
  cursor: pointer;
  transition: background var(--transition-duration);
}

input[type="checkbox"] {
  margin: 5px 5px 5px 0;
}

.footer-info,
.footer-links a {
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer-info {
  color: var(--background-color);
  margin-bottom: 10px;
  justify-content: center;
}

.center-header h1,
.footer-links {
  display: flex;
  justify-content: center;
}

.footer-links {
  gap: 15px;
  margin-bottom: 10px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.footer-links a:hover,
.page-container.toolinfos a:hover {
  text-decoration: underline;
}

.cookie-banner a:hover,
.footer-links a:hover {
  color: var(--background-color);
  opacity: 0.8;
}

.footer-disclaimer {
  font-size: 12px;
  opacity: 0.8;
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--nav-bg);
  color: var(--background-color);
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  z-index: 1000;
  box-shadow: 0-2px 10px rgba(0, 0, 0, 0.3);
}

.cookie-banner a {
  text-decoration: underline;
}

.cookie-buttons {
  display: flex;
  gap: 12px;
}

.accept-btn,
.cookie-banner a,
.decline-btn {
  font-weight: 700;
  color: var(--background-color);
}

.accept-btn,
.decline-btn {
  padding: 10px 18px;
  border: 0;
  cursor: pointer;
  border-radius: var(--nav-border-radius);
  font-size: 14px;
  transition: background var(--transition-duration);
  background-color: var(--primary-color);
}

.accept-btn:hover,
button:hover {
  background-color: var(--toolbar-hover-bg);
}

.decline-btn {
  background-color: var(--secondary-color);
}

.decline-btn:hover {
  background-color: var(--nav-bg);
}

.page-container hr {
  width: 30%;
  margin: 20px auto;
  border: 0;
  height: 1px;
  background-color: #000;
}

.center-header h1 {
  margin-top: 10px;
}

.nav-title-line {
  display: flex;
  -webkit-flex-direction: row;
  width: 100%;
  gap: 12px;
  align-items: center;
  text-decoration: none;
}

.nav-logo {
  width: 40px;
  height: auto;
}

.language-selector-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.language-selector-container select {
  padding: 5px;
  margin-left: 10px;
  border-radius: 4px;
  background-color: #fff;
  border: 0;
  font-size: 14px;
  width: auto;
  min-width: 80px;
}

.page-container.toolinfos {
  background: var(--background-color);
  padding: var(--global-padding);
  border-radius: var(--nav-border-radius);
  box-shadow: 0 4px 10px var(--nav-shadow);
  margin: 20px auto;
}

.page-container.toolinfos h2 {
  font-size: var(--h2-font-size);
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 6px;
  margin-bottom: 12px;
}

.page-container.toolinfos p {
  color: var(--primary-color);
  line-height: 1.6;
  margin-bottom: 10px;
}

.page-container.toolinfos ul {
  list-style: none;
  padding-left: 0;
}

.page-container.toolinfos ul li {
  background: rgba(0, 123, 255, 0.1);
  padding: 8px 12px;
  margin-bottom: 8px;
  border-left: 4px solid var(--primary-color);
  border-radius: var(--nav-border-radius);
}

.page-container.toolinfos a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
}

.page-container.toolinfos .faq-section ul li {
  margin-bottom: 12px;
}

.page-container.toolinfos .faq-section strong {
  font-weight: 700;
  color: var(--primary-color);
}

.editor-container {
  display: flex;
  flex-direction: row;
  gap: var(--primary-gap);
}

@media (max-width: 768px) {
  .editor-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .editor-box {
    width: 100% !important;
  }

  .menu-container {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-top {
    display: none !important;
  }
}

/* Add this to your global CSS file */
.fade-in {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}

/* Font Awesome Regular Icon (fallback if not using Font Awesome CDN) */
.far {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

/* Info Circle Icon */
.fa-info-circle:before {
  content: "\f05a"; /* Unicode for info-circle */
}

/* Info text color (Bootstrap 'text-info' equivalent) */
.text-info {
  color: #17a2b8 !important;
}

/* Optional: Tooltip cursor and transition */
[data-toggle="tooltip"] {
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

/* Optional: Hover effect */
[data-toggle="tooltip"]:hover {
  color: #138496 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Center FileDropZone contents */
[role="presentation"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 28rem; /* Adjust as needed (md = 28rem) */
  width: 100%;
}

.how-to-visual-guide {
  max-width: 800px;
  margin: 0 auto;
}
.step-card {
  transition: transform 0.2s;
}
.step-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
